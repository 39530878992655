<template>
  <el-card class="box-card">
    <div class="title">
      <Header />
    </div>
    <Headers />
    <el-row class="mt30" :gutter="30">
      <el-col :span="8">
        <div class="bottom-item">
          <img width="140" :src="img3" />
          <div class="bottom-item-title">{{ translateTitle("银行账号转账") }}</div>
          <div class="bottom-item-des">{{ translateTitle("免费支付") }}</div>
          <el-button
            :loading="loading1"
            class="sure-btn"
            @click="transfer(3)"
            >{{ translateTitle("银行账号转账") }}</el-button
          >
        </div>
      </el-col>
      <el-col :span="8">
        <div class="bottom-item bottom-item1">
          <img width="140" :src="img2" />
          <div class="bottom-item-title">{{ translateTitle("国内银行") }}</div>
          <div class="bottom-item-des">
            {{ translateTitle("通过银行账户支付") }}
          </div>
          <el-button class="defo-btn" :loading="loading1" @click="transfer(0)"
            >{{ translateTitle("开发中") }}</el-button
          >
        </div>
      </el-col>
      <el-col :span="8">
        <div class="bottom-item bottom-item1">
          <img width="140" :src="img1" />
          <div class="bottom-item-title">{{ translateTitle("国际账户") }}</div>
          <div class="bottom-item-des">
            {{ translateTitle("通过银行账户支付") }}
          </div>
          <!-- <el-button :loading="loading1" class="defo-btn" @click="transfer(1)"
            >SWIFT {{ translateTitle("转账") }}</el-button
          > -->
          <el-button
            :loading="loading1"
            class="defo-btn"
            @click="transfer(2)"
            >{{ translateTitle("开发中") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
    <DialogPop
      width="700px"
      :title="translateTitle('银行账户转账')"
      :visible="visible"
      :closeBtn="false"
      :btnloading="btnloading"
      :sureText="translateTitle('转账')"
      @onClose="onClose"
      @onSure="onSure('ruleForm')"
    >
      <div class="change-form">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item :label="translateTitle('银行卡号')" prop="bankCardNo">
            <el-input
              size="large"
              :placeholder="translateTitle('请输入银行卡号')"
              v-model="ruleForm.bankCardNo"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="translateTitle('数量')" prop="amount">
            <el-input
              :placeholder="translateTitle('请输入数量')"
              size="large"
              v-model="ruleForm.amount"
              class="input-with-select"
            >
              <template slot="append"><img width="24" src="../../assets/imgs/icon_usdt@2x.png">USDT</template>
             
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="">
            <div class="line2">
              <span class="left">{{ translateTitle("可用余额") }}</span>
              <span class="right">&nbsp;{{ BalanceCoinNam }} USDT</span>
            </div>
            <div v-if="ruleForm.amount" class="line3">
              <span class="left">{{ translateTitle("手续费") }}</span>
              <span class="right"
                >&nbsp;{{ transferAmount || 0 }} USDT</span
              >
            </div>
            <div v-if="ruleForm.amount" class="line3">
              <span class="left">{{ translateTitle("实际到账") }}</span>
              <span class="right"
                >&nbsp;{{ subtr(ruleForm.amount, transferAmount) || 0 }} USDT</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="change-check">
              <el-checkbox v-model="checked"></el-checkbox
              >&nbsp;{{ translateTitle('我同意本网站的')}}&nbsp;<span class="base-color"
                >{{translateTitle('条款和条件')}}</span
              >
            </div>
    </DialogPop>
  </el-card>
</template>

<script>
import {
  coinNames,
  getBalanceCoinNam,
  userBankBalance,
  coinLink,
  coinBalanceUSD,
  getTransferRate,
} from "@/api/index";
import Headers from "../../components/accountHeader/index.vue";
import { coin_transfer, bank_transfer,bank_card_transfer } from "@/api/accountManage";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr} from '@/utils/date'
export default {
  components: { Headers },
  data() {
    return {
      value1: false,
      img1: require("@/assets/imgs/pic_zhuanzhang_guoji@2x.png"),
      img2: require("@/assets/imgs/pic_zhuanzhang_guonei@2x.png"),
      img3: require("@/assets/imgs/pic_zhuanzhang_mcard@2x.png"),
      loading1: false,
      visible: false,
      checked: false,
      isCheck: "1",
      ruleForm: {
        email: "",
        amount: "",
        coinName: "",
        coinLink: "",
        bankCardNo: '',
      },
      choiceCoin: {
        coinName: '',
        coinLogo: "",
      },
      rules: {
        amount: [
          {
            required: true,
            message: this.translateTitle("请输入数量"),
            trigger: "change",
          },
        ],
        bankCardNo: [
          {
            required: true,
            message: this.translateTitle("请输入银行卡号"),
            trigger: "change",
          },
        ],
      },
      coinNameData: [],
      BalanceCoinNam: "",
      CoinLinkOption: [],
      btnloading: false,
      transferRate: '',
      transferAmount: '',
      globalLoading: null,
    };
  },
  created() {
    // this.globalLoading = this.$loading({
    //           lock: true,
    //           text: '',
    //           spinner: '',
    //           background: 'rgba(255, 255, 255, 0.8)'
    //         });
    // userBankBalance().then((res) => {
    //   if(res.code == 200) {
    //     this.BalanceCoinNam = Number(res.data.amount).toFixed(2);
    //   } else {
    //     this.$message.error(this.translateTitle(res.msg));
    //   }
    // }).finally(()=> {
    //   this.globalLoading.close()
    // });
    // this.getCoinName()
  },
  watch: {
    'ruleForm.amount': function (params) {
      if (params == '') { this.transferAmount = 0; } else { this.transferAmount = multiply(this.transferRate,params); }
    },
  },
  methods: {
    multiply,
    subtr,
    translateTitle,
    transfer(val) {
      if (val == "3") {
      //   this.visible = true;
      //   let params = {
      //   coinLink: 'USD',
      //   coinName: 'USD',
      //   type: '0'
      // }
      // getTransferRate(params).then(res => {
      //   this.transferRate = res.data.rate
      // })
      this.$router.push('/transferMoney/detail')
      } else {
        this.$message.warning(this.translateTitle("该功能敬请期待"));
      }
    },
    getBalanceCoin(type) {
      if (type == "1") {
        getBalanceCoinNam(this.ruleForm.coinName).then((res) => {
          if(res.code == 200) {
            this.BalanceCoinNam = Number(res.data.amount).toFixed(2);
          } else {
            this.$message.error(this.translateTitle(res.msg));
          }
        });
      } else {
        if (this.ruleForm.coinName && this.ruleForm.coinLink) {
          let params = {
            coinName: this.ruleForm.coinName,
            coinLink: this.ruleForm.coinLink,
          };
          coinBalanceUSD(params).then((res) => {
            if(res.code == 200) {
              this.BalanceCoinNam1 = res.data.balance;
            } else {
              this.$message.error(this.translateTitle(res.msg));
            }
          });
        }
      }
    },
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
    async getCoinName(val) {
      let res = await coinNames();
      this.coinNameData = res.data;
      this.ruleForm.coinName = this.coinNameData[0].coinName;
      this.choiceCoin.coinName = this.coinNameData[0].coinName;
      this.choiceCoin.coinLogo = this.coinNameData[0].coinLogo;
      this.getCoinLink(this.ruleForm.coinName);
      this.getBalanceCoin(val);
    },
    changeCoinName(data) {
      let obj  = this.coinNameData.find(item => item.coinName === data)
      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLogo = obj.coinLogo;
      this.ruleForm.coinName = obj.coinName;
      this.ruleForm.coinLink = "";
      this.getCoinLink(this.ruleForm.coinName);
      this.getBalanceCoin(2);
    },
    onSure(formName) {
      if (this.isCheck == "") {
        return this.$message.error(this.translateTitle("请先选择转账方式"));
      } else {
        let _resquest;
        let params;
          _resquest = bank_card_transfer;
          params = {
            amount: this.ruleForm.amount,
            bankCardNo: this.ruleForm.bankCardNo,
          };
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.btnloading = true
            this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
            _resquest(params).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                // this.getContact()
                this.visible = false;
                this.$refs["ruleForm"].resetFields();
              } else {
                this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              // this.btnloading = false
              this.globalLoading.close()
            });
          } else {
            return false;
          }
        });
      }
    },
    onClose() {
      this.$refs["ruleForm"].resetFields();
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-bottom: 20px;
  text-align: right;
}
.bottom-title {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  text-align: center;
  margin: 30px 0;
}
.bottom-item {
  width: 100%;
  //   height: 420px;
  background: #f8fafb;
  border-radius: 16px;
  text-align: center;
  padding:75px 0 50px 0;
  .bottom-item-title {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #24282e;
    margin: 20px;
  }
  .bottom-item-des {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    // font-weight: bold;
    color: #b9bfca;
    margin-bottom: 40px;
  }
  .defo-btn { 
       background: #281800 !important;
       border-radius: 12px !important;
       color: #FFFFFF !important;
  }

  ::v-deep {
    .el-button--medium {
      width: 70%;
      height: 66px;
      background: #353a42;
      border: 1px solid #e2eaee;
      border-radius: 16px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.bottom-item1 {
  // opacity: 0.5;
}
::v-deep {
  .custum-dialog {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .img {
      text-align: center;
      background: #f2f7f9;
      border-radius: 10px;
      padding: 20px 0px;
      width: 46%;
      border: 2px solid #f2f7f9;
      p {
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: bold;
        color: #24282e;
      }
      // img {
      //   width: 60%;
      // }
    }
    .img.active {
      border: 2px solid #f6d655;
    }
  }
}
.change-form {
  background: #f2f7f9;
  padding: 30px;
  border-radius: 10px;

  ::v-deep {
    .el-input__inner {
      height: 50px !important;
      line-height: 50px !important;
      border: none !important;
    }
    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #06a43e !important;
      border-color: #06a43e !important;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }
    .el-checkbox__inner:hover {
      border-color: #000;
    }
    .input-with-select .el-input-group__append {
      background-color: #fff;
      border: none;
      width: 110px;
    }
  }
}
</style>
