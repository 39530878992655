/**
 * 日期相关操作封装
 */
/* 补零 */
export function padZero(n) {
    return n < 10 ? '0' + n : n;
}

/* 获取年月日 */
export function getYMD(separator = '/', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)

    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()

    // return y + s + padZero(m) + s + padZero(d)
    return [y, m, d].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? '/' : separator)
}

/* 获取时分秒 */
export function getHMS(separator = ':', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()

    return [h, m, s].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? ':' : separator)
}

/* 获取年月日 时分秒 */
export function getYMDHMS(s1, s2, t) {
    return getYMD(s1, t) + ' ' + getHMS(s2, t)
}

/* 获取星期 */
export function getWeek(t = Date.now()) {
    return '星期' + ['天', '一', '二', '三', '四', '五', '六'][new Date(t).getDay()]
}

/**
 * 加
 */
export function addtr(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split(".")[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  }
  /**
   * 减
   */
  export function subtr(arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split(".")[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(2);
  }
export function multiply(arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { }
    try { m += s2.split(".")[1].length } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}
export function division(num1, num2) {
    const num1String = num1.toString()
    const num2String = num2.toString()
    const num1Digits = (num1String.split('.')[1] || '').length
    const num2Digits = (num2String.split('.')[1] || '').length
    const baseNum = Math.pow(10, num1Digits + num2Digits)
    const n1 = times(num1, baseNum)
    const n2 = times(num2, baseNum)
    return Number(n1) / Number(n2)
  }
  export function times(num1, num2) {
    const num1String = num1.toString()
    const num2String = num2.toString()
    const num1Digits = (num1String.split('.')[1] || '').length
    const num2Digits = (num2String.split('.')[1] || '').length
    const baseNum = Math.pow(10, num1Digits + num2Digits)
    return Number(num1String.replace('.', '')) * Number(num2String.replace('.', '')) / baseNum
  }

















